/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages
            },
            finalize: function () {
                $(window).scroll(function () {
                    var winH = 150;

                    if ($(this).scrollTop() > winH) {
                        $('.top-area').addClass("sticky");
                    }
                    else {
                        $('.top-area').removeClass("sticky");
                    }
                });

                $(".trigger-menu").click(function () {
                    $(this).toggleClass('active');
                    $('.responsive-holder').toggleClass('slide');
                });

                $( ".close-menu" ).click(function() {
                    $('.trigger-menu').removeClass('active');
                    $('.responsive-holder').removeClass('slide');
                });
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

jQuery(document).ready(function ($) {
    checkContainer();
    /*    $('.home-slider').flickity({
     // options
     cellAlign: 'left',
     pageDots: true,
     prevNextButtons: false,
     contain: true,
     selectedAttraction: 0.5,
     friction: 1
     });*/

    $('.home-slider').owlCarousel({
        items: 1,
        dots: true,
        autoplay:true,
        autoplayTimeout: 7000,
        loop: true
    });

    $('.team-slider').owlCarousel({
        items: 1,
        dots: false,
        autoplay:true,
        autoplayTimeout: 7000
    });

    $('.testimonial-slider').owlCarousel({
        items: 1,
        dots: false,
        autoplay: true,
        autoplayTimeout: 5000,
        loop: true
    });

    $('.form-opener a').click( function() {
        $(this).toggleClass('lalala');
        $('.sitewide-form').toggleClass('exposed');
    });

    $('#ship-to-different-address-checkbox').prop('checked', true);

    $('ul.products li').each(function() {
        var dataID = $(this).find('.button.yith-wcqv-button').attr('data-product_id');
        $(this).children().attr('data-product_id', dataID).addClass('yith-wcqv-button');
    });

    $('a[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                $('html, body').animate({
                    scrollTop: target.offset().top-150
                }, 1000);
                return false;
            }
        }
    });


    $(window).scroll(function() {
        var $win = $(window);
        var winH = $win.height();

        if ($(this).scrollTop() > 1) {
            $('.top-area').addClass("sticky");
            //var divHeight = $('.section.is-header').height();
            //$('.home-hero, .section.hero, #content, .product[id^=product], .people-wrap ').css('margin-top', divHeight+'px');
            //$('.yith-wcqv-main .product[id^=product]').css('margin-top', '0');
        }
        else{
            $('.top-area').removeClass("sticky");
            //var divHeight = $('.section.is-header').height();
            //$('.home-hero, .section.hero, #content, .product[id^=product], .people-wrap').css('margin-top', '0');
        }
    });

    var divWidth = $('.wrapper').width();
    $('.section.is-header').css('width', divWidth+'px');

    $( window ).resize(function() {
        var divWidth = $('.wrapper').width();
        $('.section.is-header').css('width', divWidth+'px');
    });

    var ScrollerHeight = $('.innerpage-menu').height();
    $('.scroller-image-container').css('height', ScrollerHeight+'px');

    $( window ).resize(function() {
        $('.scroller-image-container').css('height', ScrollerHeight+'px');
    });

    $(".responsive-holder .menu li.menu-item-has-children").append('<i class="fa fa-angle-down"></i>');

    function checkContainer () {
        if($('.responsive-tabs-wrapper').is(':visible')){ //if the container is visible on the page
            $(".responsive-tabs .responsive-tabs__list__item").wrapInner( "<h4></h4>" );  //Adds a grid to the html
        } else {
            setTimeout(checkContainer, 50); //wait 50 ms, then try again
        }
    }

    $(".responsive-holder .menu li.menu-item-has-children").children("i, a").on("click", function(e) {
        e.preventDefault(), $(this).parent().find('.sub-menu').toggleClass("submenu-open").next(".sub-menu").end().parent(".menu-item-has-children").siblings(".menu-item-has-children").children("a").removeClass("submenu-open").next(".sub-menu");
    });

    $('#duplicate-billing-address').removeAttr('checked');

    $( "#duplicate-billing-address" ).change(function() {
        if($(this).is(":checked")) {
            //if checked then copy all values
            $("#order_company").val($('#bill_company').val());
            $("#order_people_tile").val($('#person_title').val());
            $("#order_phone").val($('#billing_phone').val());
            $("#order_mobile").val($('#mobile_phone').val());
            $("#shipping_first_name").val($('#billing_first_name').val());
            $("#shipping_last_name").val($('#billing_last_name').val());
            $("#shipping_company").val($('#billing_company').val());
            $("#shipping_address_1").val($('#billing_address_1').val());
            $("#shipping_address_2").val($('#billing_address_2').val());
            $("#shipping_city").val($('#billing_city').val());
            $("#shipping_postcode").val($('#billing_postcode').val());
            $("#shipping_state").val($('#billing_state').val());
            $("#shipping_country").val($('#billing_country').val());

            //WooCommerce 2.3?? introduced predictive dropdown selectors
            if( $('#s2id_shipping_country').length ) {
                //Shipping Country predictive dropdown
                $('#select2-chosen-2').text($('#select2-chosen-1').text());
            }
            if( $('#s2id_shipping_state').length ) {
                //Shipping State predictive dropdown
                $('#select2-chosen-4').text($('#select2-chosen-3').text());
            }
        }
        else {
            //Clear values when unchecked
            $("#order_company").val('');
            $("#order_people_tile").val('');
            $("#order_phone").val('');
            $("#order_mobile").val('');
            $("#shipping_first_name").val('');
            $("#shipping_last_name").val('');
            $("#shipping_company").val('');
            $("#shipping_address_1").val('');
            $("#shipping_address_2").val('');
            $("#shipping_city").val('');
            $("#shipping_postcode").val('');
            $("#shipping_state").val('');
            $("#shipping_country").val('');

            if( $('#s2id_shipping_country').length ) {
                //Shipping Country predictive dropdown
                $('#select2-chosen-2').text('');
            }
            if( $('#s2id_shipping_state').length ) {
                //Shipping State predictive dropdown
                $('#select2-chosen-4').text('');
            }
        }
    });
});


